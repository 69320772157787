import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  Fragment,
} from 'react';

import icComments from './assets/images/ic-comments.png';
import imgKkyLogo from './assets/images/kky-logo.jpg';

import ReactPlayer from 'react-player';

import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import './styles.scss';

import content from './content/content';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import { Fab } from './components';
import CardColumns from 'react-bootstrap/CardColumns';

const ModalContext = createContext(null);

function Header() {
  return (
    <Carousel className="mb-2">
      <Carousel.Item>
        {/*<div className="d-block w-100" style={{height: "50vh", backgroundImage: `url(${imgKkyLogo})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}} />*/}
        <div className="w-100 text-center" style={{ opacity: 0.3 }}>
          <img
            style={{ maxWidth: 300 }}
            className="my-5"
            src={imgKkyLogo}
            alt="First slide"
          />
        </div>
        <Carousel.Caption style={{ color: 'black' }}>
          <h3>Kavalam Kudumbayogam</h3>
          <p>Annual Gathering 2020</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
function MediaPlayer({ mediaUrl, type, currentMediaId, onPlay }) {
  return (
    <ReactPlayer
      className={type === 'video' ? 'video-player' : 'audio-player'}
      url={mediaUrl}
      controls={true}
      width={'100%'}
      playing={currentMediaId === mediaUrl}
      onPlay={() => onPlay(mediaUrl)}
    />
  );
}

function Comment({ personName, text }) {
  return (
    <blockquote className="blockquote mb-3">
      <p>{newLineToBr(text)}</p>
      <footer className="blockquote-footer">{personName}</footer>
    </blockquote>
  );
}

function newLineToBr(text) {
  return (
    text &&
    text.split('\n').map((item, key) => {
      return (
        <Fragment key={key}>
          {item}
          <br />
        </Fragment>
      );
    })
  );
}

function TimelineCard({
  personName,
  title,
  text,
  imageUrl,
  audioUrl,
  videoUrl,
  currentMediaId,
  onPlay,
  comments,
}) {
  const { promptModal } = useContext(ModalContext);

  function showComments() {
    promptModal(
      <>
        <Modal.Header closeButton>
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {comments.map((comment) => (
            <Comment {...comment} />
          ))}
        </Modal.Body>
      </>
    );
  }

  text = newLineToBr(text);

  const originalText = text;
  function showFullText() {
    promptModal(
      <>
        <Modal.Header closeButton>
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>{originalText}</Modal.Body>
      </>
    );
  }

  const MAX_LEN = 200;
  if (text) {
    if (text.length > MAX_LEN) {
      text = (
        <span>
          {text.substring(0, MAX_LEN) + '... '}
          <a href={'#'} onClick={showFullText}>
            (Read more)
          </a>
        </span>
      );
    }
  }

  const isShortTitle = title && title.length < 3;

  return (
    <Card className="kky-card kky-card-text text-left">
      {imageUrl && <Card.Img variant="top" src={imageUrl} />}
      <Card.Body>
        {title && !isShortTitle && (
          <Card.Title className="kky-card__title mb-1">{title}</Card.Title>
        )}
        <Card.Text className="kky-card__author mb-2 text-muted">
          {isShortTitle && (
            <span>
              {title} {personName}
            </span>
          )}
          {!isShortTitle && personName}
        </Card.Text>

        {text && (
          <Card.Text className="kky-card-text__description text-muted">
            {text}
          </Card.Text>
        )}
        {audioUrl && text && <div className="mt-2" />}
        {audioUrl && (
          <MediaPlayer
            type={'audio'}
            onPlay={onPlay}
            currentMediaId={currentMediaId}
            mediaUrl={audioUrl}
          />
        )}
        {videoUrl && (text || audioUrl) && <div className="mt-2" />}
        {videoUrl && (
          <MediaPlayer
            type={'video'}
            onPlay={onPlay}
            currentMediaId={currentMediaId}
            mediaUrl={videoUrl}
          />
        )}

        {comments && comments.length > 0 && (
          <div className="text-right">
            <Card.Link href="#" onClick={showComments}>
              <img className="ic-comments" src={icComments} alt={'Comments'} />
            </Card.Link>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

function TimelineItem({ type, ...otherProps }) {
  if (type === 'card') {
    return <TimelineCard {...otherProps} />;
  } else {
    return <code>Unknown timeline item: {type}</code>;
  }
}

export default function App() {
  const [modalContent, setModalContent] = useState(null);
  const [currentMediaId, setCurrentMediaId] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const promptModal = useCallback((content) => {
    setModalContent(content);
    setShow(true);
  }, []);

  function showFeedbackForm() {
    promptModal(
      <>
        <Modal.Header closeButton>
          <Modal.Title>Feedback form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            scrolling="no"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdtqep0T531NXAQYwTZvH31JNL_eDhaxevmgJn6zMMla5AxkA/viewform?embedded=true"
            width="100%"
            height="1000"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </Modal.Body>
      </>
    );
  }
  return (
    <ModalContext.Provider value={{ promptModal }}>
      <Header />
      <Container fluid>
        <CardColumns>
          {content.timeline.map((data, i) => (
            <TimelineItem
              key={i}
              {...data}
              onPlay={(mediaId) => setCurrentMediaId(mediaId)}
              currentMediaId={currentMediaId}
            />
          ))}
        </CardColumns>
        <div className="mt-5" />
        <Modal show={show} onHide={handleClose}>
          {modalContent}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <Fab onClick={showFeedbackForm} />
    </ModalContext.Provider>
  );
}
