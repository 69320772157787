import React from 'react';
import './fab.scss';

export function Fab({ onClick }) {
  return (
    <div
      className="fab d-flex justify-content-center align-items-center"
      onClick={onClick}
    >
      <span>?</span>
    </div>
  );
}
